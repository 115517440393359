/* Regular @import url statements work as you'd expect them to -- alternatively
you can import locally, and they wind up in the resulting bundle */
@import url('https://fonts.googleapis.com/css?family=Gentium+Basic');
@import url('../node_modules/antd/dist/antd.min.css');
@import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');
@import url('../node_modules/axios-progress-bar/dist/nprogress.css');
@import url('../node_modules/react-big-calendar/lib/addons/dragAndDrop/styles.css');
@import url('../node_modules/react-big-calendar/lib/css/react-big-calendar.css');
@import url('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
@import url('../node_modules/@sakitam-gis/react-map/dist/react-map.min.css');
@import url('../node_modules/maptalks-awesome-markers/dist/maptalks-awesome-marker.css');
@import url('../node_modules/react-circular-progressbar/dist/styles.css');
@import url('../node_modules/react-chat-widget/lib/styles.css');

@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.08);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.map-content {
  height: 100% !important;
}

.maptalks-dropMenu {
  top: 40px !important;
  padding-top: 2px
}

.maptalks-dropMenu li a {
  color: black !important;
}

.maptalks-dropMenu li {
  max-width: 200px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}

.maptalks-dropMenu a {
  width: 100% !important;
  height: 100% !important
}

.maptalks-dropMenu li a span {
  text-indent: 0 ! important;
  margin: 0;
  padding: 0;
  left: 5px;
  position: absolute;
  top: 7px;
  color: white !important;
}

.maptalks-dropMenu li a i {
  margin-right: 5px;
  background: none !important;
}

.maptalks-dropMenu li a {
  background: none !important;
}

.maptalks-dropMenu ul {
  list-style-type: none !important;
}

.anticon {
  vertical-align: 0.15em !important;
}

.ant-modal {
  min-width: 620px !important;
}

.maptalks-menu {
  background: rgba(0, 0, 0, 0.404) !important;
  color: white !important;
  border-radius: 4px;
}

.maptalks-menu-items li {
  background: rgba(0, 0, 0, .404) !important;
  color: white !important;
}

li.maptalks-menu-splitter,
li.maptalks-menu-splitter:hover {
  background: rgba(255, 153, 0, 0.993) !important;
  height: 1px !important;
}

.maptalks-toolbar-hx li,
.pegman {
  height: 40px !important;
  min-width: 40px;
  background: rgba(0, 0, 0, .65) !important;
  border: 0px;
  margin-right: 2px;
  padding: 0px;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  color: white !important;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.maptalks-dropMenu li:hover,
.maptalks-toolbar-hx li:hover,
.maptalks-menu-items li:hover {
  background: rgb(255, 154, 0) !important;
}

#map img[role=presentation] {
  width: 257px !important;
  height: 257px !important;
}

.ant-collapse-content-active {
  overflow: visible !important;
}

.rdw-image-modal {
  z-index: 1000 !important;
}

.ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: calc(100% - 35px) !important;
}

.DraftEditor-editorContainer {
  border: 1px solid #f1f1f1 !important;
  margin-bottom: 10px;
  min-height: 100px;
}

.ant-card-head {
  background: #fafafa !important;
}

.anticon-loading {
  top: -3px;
  position: relative !important;
}

.ant-collapse-header p {
  top: 4px;
  position: relative !important;
}

.ant-card-body>.ant-collapse-header {
  margin: -10px 0 -10px 0 !important;
}

.ant-comment-actions button {
  line-height: 1;
}

.commentImg {
  margin: 0 10px;
}

.ant-comment-avatar img {
  border: 1px solid #f1f1f1 !important;
}

.ant-comment-content {
  top: -5px;
}

.rdw-image-right {
  float: right;
}

.rdw-image-right {
  float: right;
  margin-left: 10px;
}

.rdw-image-left {
  float: left;
  margin-right: 10px;
}

.ant-comment-actions {
  clear: both;
}

.ant-modal-close {
  border-image: none;
}

.ant-modal-close-x {
  display: block;
  width: 26px !important;
  height: 26px !important;
  font-size: 16px !important;
  line-height: 26px !important;
  font-style: normal;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  border-image: none;
}

#dictFrame {
  height: calc(100vh - 220px);
}

.pulse {
  width: 400px;
  animation-name: pulse_animation;
  animation-duration: 3000ms;
  transform-origin: 70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

html {
  padding: 0;
  border: 0;
  font-family: 'Gentium Basic', serif;
  font-size: 16px;
  overflow-y: unset;
}

.batStatistic {
  color: white !important;
  padding: 10px !important;
  margin-left: -10px !important;
  margin-right: 10px !important;
  border: 1px solid #80808033;
  width: calc(100% + 60px);
}

.global-search {
  border: none !important;
}

.ant-select-search__field {
  top: -2px !important;
}

.ant-statistic {
  padding: 5px !important;
  width: 100%;
}

.clientLogoInList {
  width: 40px;
}

.positif {
  background-color: rgb(221, 77, 51) !important;
  color: white ! important;
  text-shadow: 0 0 0.2em rgba(0, 0, 0, .4) !important;
}

.negatif {
  background-color: #ad3 !important;
  color: white ! important;
  text-shadow: 0 0 0.2em rgba(0, 0, 0, .4) !important;
}

/* antd step finish icon*/
.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: rgba(0, 0, 0, .85) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #ad3 !important;
  background-color: #ad3 !important;
}

.ant-steps-item-finish>.ant-steps-item-content>.ant-steps-item-title:after {
  background-color: #ad3 !important;
}

.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  margin-left: 35px !important;
}

/* .ant-form-item-children-icon {
  right: 45px !important;
  position: relative !important;
} */

.draggable-tree>.ant-tree-node-content-wrapper {
  width: calc(100% - 10%);
  text-overflow: ellipsis;
  overflow: hidden;
  height: 30px !important;
  vertical-align: middle !important;
  margin-top: -4px !important;
}

.draggable-tree>.ant-tree-node-content-wrapper>span:first-child {
  height: 30px !important;
  vertical-align: middle !important;
  padding-top: 4px;
}

/* .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 12px 16px 4px 40px !important;
} */

span.ant-tree-iconEle {
  font-size: 16px !important;
  top: -1px !important;
}

.gmaps * {
  box-sizing: content-box;
}

/* .ant-select-auto-complete.ant-select-lg .ant-input {
  height: 40px;
  padding-top: 5px !important;
  padding-bottom: 4px !important;
} */

/* button .anticon {
  position: relative;
  top: -3px;
} */

a:hover {
  text-decoration: none !important;
}

.ant-layout {
  overflow: auto !important;
}


/* .ant-card-bordered {
  box-shadow: 0 10px 10px -9px black;
}

.react-bootstrap-table, .card {
  box-shadow: 0 4px 30px hsl(216, 5%, 92%);
} */

.table-hover>tbody>tr:hover {
  background-color: #188fff18 !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 85px !important;
  height: 85px !important;
  position: static;
  object-fit: contain;
}

.draggable-tree>.ant-tree-node-content-wrapper {
  width: 90%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 30px !important;
  vertical-align: middle !important;
  margin-top: -4px !important;
}

.draggable-tree>.ant-tree-node-content-wrapper>span:first-child {
  height: 30px !important;
  vertical-align: middle !important;
  padding-top: 4px;
}

#pageDropDown {
  margin-left: 10px;
}

.ant-layout-sider-collapsed .nav-text {
  display: none;
}

.ant-layout-sider-collapsed .ant-menu-submenu-vertical>.ant-menu-submenu-title:after {
  display: none;
}

.pagination {
  justify-content: flex-end;
  margin-right: 10px;
}

.ant-table td {
  white-space: nowrap;
}

.logo {
  position: absolute;
  width: 90px;
  z-index: 100;
  top: 8px;
  left: -30px;
}

.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

#login-logo {
  width: 400px;
  background: black;
  position: relative;
  z-index: 1000;
  top: -15px;
  max-width: 106%;
}

.ant-col {
      flex: 1 1 auto !important;
}

.search-btn {
  margin-right: -11px;
}

.highlight {
  color: #f50;
}


span.fa {
  margin-right: 10px;
}

button>span.fa {
  margin-right: 0px;
}

.ant-card-actions {
  background: rgba(92, 96, 99, 0.294) !important;
  color: white;
}


/* scrollbars */
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track-piece:decrement,
::-webkit-scrollbar-track-piece:increment {
  width: 0px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

button#urgence.ant-switch.ant-switch-checked {
  background-color: #fb3d34 !important;
}

button#hasHapQuali.ant-switch.ant-switch-checked {
  background-color: #ff8405 !important;
}

button#hasHapQuanti.ant-switch.ant-switch-checked {
  background-color: #fb3d34 !important;
}

.location-search-input::placeholder {
  color: white !important;
  opacity: 1;
  /* Firefox */
}

.map-search>input:hover,
.map-search>input:focus {
  border-color: rgb(255, 154, 0) !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

.ant-select-dropdown-menu-item.map-search-item:hover,
.ant-select-dropdown-menu-item-active.map-search-item {
  background-color: rgba(255, 153, 0, 0.712) !important;
}

.ant-select-auto-complete.ant-select-lg .ant-input {
  padding-top: 2px !important;
  padding-bottom: 4px !important;
}

.ant-carousel .slick-slide {
  height: 160px;
  line-height: 160px;
  background: #ffffff;
  overflow: hidden;
}

.ant-carousel .slick-track div:first-child {
  display: flex !important;
  justify-content: center;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.ant-carousel .slick-dots li button {
  height: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.363) !important;
  margin-top: 20px !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background: rgb(255, 154, 0) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.ant-card-body {
  padding: 10px !important;
  zoom: 1;
}

.noPlans {
  line-height: 1 !important;
  text-align: center !important;
}

.infowindowLarge {
  min-width: 650px;
}

.infowindowSmall {
  min-width: 250px;
}

.infowindowSmall h2 {
  width: 350px !important;
}

.maptalks-msgBox h2 {
  line-height: 30px;
  font-weight: 700;
  font-size: 14px;
  padding: 0 10px;
  margin: 0;
  height: auto;
}

.maptalks-layer-switcher {
  position: absolute !important;
  bottom: 60px !important;
  right: 0px !important;
  height: 40px !important;
  min-width: 40px;
  background: rgba(0, 0, 0, .65) !important;
  border: 0px;
  margin-right: 2px;
  padding: 0px;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  color: white !important;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.shown {
  height: auto !important;
  background-color: rgba(0, 0, 0, .65) !important;
  color: white !important;
}

.shown input {
  position: relative !important;
  top: -10px !important;
}

.maptalks-layer-switcher .panel {
  background-color: rgba(0, 0, 0, .65) !important;
}

.ant-drawer-body div {
  max-height: calc(100vh - 200px)
}

.removeUploadFile {
  padding: 4px;
  border: 1.02px solid #d9d9d9;
  border-radius: 4px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.removeUploadFile:hover {
  cursor: pointer;
}

#streetview {
  height: calc(100vh - 211px) !important;
}

.removeKml {
  margin-left: 10px;
  color: #bebebe;
  height: 32px;
}

.removeKml:hover {
  cursor: pointer;
  color: #FF1F00
}

.prospectsEventsContainer>.ant-list-header {
  position: sticky !important;
  top: 0;
  z-index: 1;
  width: '100%';
  background-color: white !important
}

.rbc-event-label {
  display: none !important;
}

.pagination {
  font-size: 1rem;
  margin: -5px 5px 5px 0 !important;
}

.react-bs-table-sizePerPage-dropdown {
  bottom: 5px !important;
  float: left;
}

/* chat */
.rcw-conversation-container > .rcw-header, .rcw-launcher  {
  background-color: rgb(255, 154, 0) !important;
}

.rcw-message > .rcw-response, .rcw-message-text {
  background-color:rgba(0, 0, 0, 0.493) !important;
  color: white !important;
}

.ant-select-auto-complete.ant-select-lg .ant-input {
  margin-left: -20px !important;
}

.dropdown-item a {
  display:block;
}