/* Define some variables we might want to use elsewhere */
:root {
  --bg: #00bcd4;
}

.example {
  background-color: var(--bg);
}

.ant-layout-sider-zero-width-trigger {
  top: 5px !important;
  right: 20 !important;
  background-color: #001529ea !important;
  border-bottom-right-radius: 14px !important;
  border-top-right-radius: 14px !important;
  padding: 2px !important;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

#search-bar-0 {
  width: 90% !important;
}

.table-responsive > .table-bordered,
.react-bootstrap-table > .table-responsive {
  border: -1px !important;
}

.table-bordered,
.react-bootstrap-table > .table-bordered {
  border: 1px solid #dee2e6 !important;
}

.flex-container {
  display: flex;
  padding: 0;
  margin: 0;
  margin: 2em auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
}
.flex-item {
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  margin: 0.1em;
  flex: 1 1 auto;
}
@media screen and (min-width: 320px) {
  .flex-item {
    width: 100vw;
  }
}
@media screen and (min-width: 640px) {
  .flex-item {
    width: calc(50vw - 4em);
  }
}
@media screen and (min-width: 960px) {
  .flex-item {
    width: calc(25vw - 4em);
  }
}
