.statsContainer {
    margin-bottom:30px;
    display:"flex";
    justify-content:"center" !important;
    align-items: "center";
  }

  .stats {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    background: #438ffe;
    padding: 10px 10px 0 10px;
    border-radius: 4px;
    color: white !important;
    vertical-align: middle;
    margin-right:20px;
  }

  .stats i, .stats h3 {
    color:white;
    font-size: 24px;
  }